import { postRequest, getRequest } from "./request";
// 人员详情
export const peopleDetail2 = (data) =>
  postRequest("/api/people/detail", data, false);
export const posterTemplateList = (data) =>
  getRequest("/poster.template/query", data, false);
export const posterTemplateDetail = (data) =>
  postRequest("/poster.template/detail", data, false);
export const getWxConfig = (data) =>
  postRequest("/wechat/getJsSign", data, false);
export const configShare = (data) => postRequest("/config/index", data, false);
