import { getWxConfig, configShare } from "@/api/apiData";
var wx = require("weixin-js-sdk");
export default {
  data() {
    return {
      appId: "",
      jssdk_share_image: "",
      jssdk_share_title: "",
      jssdk_share_desc: "",
    };
  },
  methods: {
    // 获取微信配置
    getWechatConfig(h, tit) {
      let href = "http://ncxjljyg.nccloudmedia.com";
      if (h) {
        href = h;
      }
      const that = this;
          configShare().then((shareData) => {
        //   console.log(shareData);
        getWxConfig(
          {
            url: href,
          },
          false
        ).then((res) => {
          if (res.code == 1) {
            let imgs = shareData.data.share_image;
            let tits = shareData.data.share_title;
            let desc = shareData.data.share_desc;
            console.log({ tits, imgs, desc });
            that.appId = res.data.appId;
            that.jssdk_share_title = tits;
            that.jssdk_share_image = imgs;
            that.jssdk_share_desc = desc;
            localStorage.setItem("appId", res.data.appId);
            document.title = shareData.data.share_title;
            that.wechatShare(res.data, href);
          }
        });
      });
    },
    // 分享设置
    wechatShare(data, href) {
      const that = this;
      var appId = data.appId;
      var timestamp = data.timestamp;
      var nonceStr = data.nonceStr;
      var signature = data.signature;
      var url = href;
      let imgUrl = that.jssdk_share_image;
      var title = that.jssdk_share_title;
      var desc = that.jssdk_share_desc;
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: appId, // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名，见附录1
          jsApiList: data.jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function () {
          // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
              // 设置成功
            },
            cancel: function () {},
          });
          // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
          wx.updateTimelineShareData({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
              // 设置成功
            },
            cancel: function () {},
          });
          wx.onMenuShareAppMessage({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
              // 设置成功
            },
            cancel: function () {},
          });
          wx.onMenuShareTimeline({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: url, // 分享链接
            imgUrl: imgUrl, // 分享图标
            type: "link", // 分享类型,music、video或link，不填默认为link
            success: function () {},
            cancel: function () {},
          });
        });
      }
    },
  },
  created() {},
  mounted() {},
};
