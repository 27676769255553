const routes = [
  {
    path: "/",
    component: () => import("@/view/index"),
  },
  {
    //生成海报
    name: "peoplePoster",
    path: "/peoplePoster",
    component: () => import("@/view/people/poster"),
  },
  {
    name: "mjrdsc",
    path: "/mjrdsc",
    component: () => import("@/view/mjrdsc"),
  },
  {
    name: "uploadPhoto",
    path: "/uploadPhoto",
    component: () => import("@/view/uploadPhoto"),
  },
];

export default routes;
