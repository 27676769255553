<template>
  <router-view />
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "App",
  mixins: [mixins],
  mounted() {
    this.getWechatConfig();
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
}
img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.flex {
  display: flex;
}
.center {
  align-items: center;
}
.between {
  /* 2边对齐 */
  justify-content: space-between;
}
.absolute {
  position: absolute;
}
.van-uploader__input-wrapper {
  width: 100%;
}
.van-nav-bar__text,
.van-nav-bar .van-icon {
  color: #947864 !important;
}
.van-notice-bar {
  border-radius: 20px;
  padding: 0 8px !important;
  height: 30px !important;
}
.zan {
  text-align: center;
  color: gray;
  font-size: 14px;
  img {
    width: 90px;
    margin: 20px 0;
  }
}
</style>
